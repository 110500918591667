<template>
  <div class="hello">
    <link
      rel="stylesheet"
      href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css"
      integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T"
      crossorigin="anonymous"
    />

    <div class="container">
      <div class="row justify-content-center">
        <div class="col-10">
          <h1>Auth0 Token Retriever</h1>
          <p class="mb-0">
            This application will allow you to get an access tokens for Auth0
            PKCE flow for use in developing API's
          </p>
          <p class="mt-0">
            To use this tool you must add '{{ hostname }}' to your callback URL
            in the auth0 configuration
          </p>
          <p class="mt-0">
            This tool should
            <b>only</b>
            be used for development/debugging.
          </p>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-6">
          <div class="form-group">
            <label for="exampleInputEmail1">Auth0 Domain</label>
            <input
              type="text"
              class="form-control"
              placeholder="e.g. https://dev-raee8pgs.eu.auth0.com"
              v-model="auth0Url"
            />
          </div>

          <div class="form-group">
            <label for="exampleInputEmail1">ClientID</label>
            <input
              type="text"
              class="form-control"
              placeholder="Enter the clientID of your SPA"
              v-model="clientId"
            />
          </div>

          <div class="form-group">
            <label for="exampleInputEmail1">Scope</label>
            <input
              type="text"
              class="form-control"
              placeholder="e.g. openid email"
              v-model="scope"
            />
          </div>

          <div class="form-group">
            <label for="exampleInputEmail1">Audience</label>
            <input
              type="text"
              class="form-control"
              placeholder="Enter the auth0 identifier of your application"
              v-model="audience"
            />
          </div>

          <div class="row">
            <div class="col-6">
              <a class="btn btn-warning" @click="saveDetails">Save Details</a>
            </div>
            <div class="col-6">
              <a class="btn btn-warning" @click="clearDetails">Clear Details</a>
            </div>
          </div>

          <!-- <a
            v-bind:href="
              auth0Url +
              '/authorize?response_type=code&client_id=' +
              clientId +
              '&code_challenge=' +
              encodeURIComponent(challenge) +
              '&code_challenge_method=S256&redirect_uri=' +
              hostname +
              '&scope=' +
              scope +
              '&audience=' +
              audience +
              '&state=xyzABC123'
            "
            class="btn btn-primary btn-lg text-white m-5"
          >
            Get Token
          </a> -->
          <a
            @click="getToken"
            class="btn btn-primary btn-lg text-white m-5"
          >
            Get Token
          </a>
        </div>
      </div>
      <div v-if="response" class="row">
        <div class="col-12">
          <h2>Response</h2>
          <br />
          <div
            v-for="(value, propertyName) in response"
            :key="value"
            class="form-group"
          >
            <label for="exampleInputEmail1">{{ propertyName }}</label>
            <input
              type="text"
              disabled="disabled"
              class="form-control disabled"
              :value="value"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import crypto from "crypto";

import axios from "axios";

import { useCookies } from "vue3-cookies";

import { isValidURL } from "../utilities"

function base64URLEncode(str) {
  return str
    .toString("base64")
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=/g, "");
}

function sha256(buffer) {
  return crypto.createHash("sha256").update(buffer).digest();
}

export default {
  name: "HelloWorld",
  setup() {
    const { cookies } = useCookies();
    return { cookies };
  },
  data() {
    return {
      challenge: "",
      verifier: "",
      auth0Url: "",
      clientId: "",
      scope: "",
      audience: "",
      saveValues: false,
      response: "",
      hostname: "https://" + location.host,
    };
  },
  methods: {
    getToken(){
      if(!isValidURL(this.auth0Url)){
        this.$toast.error("Invalid Auth0 Domain URL");
        return;
      }

      let url = this.auth0Url +
              '/authorize?response_type=code&client_id=' +
              this.clientId +
              '&code_challenge=' +
              encodeURIComponent(this.challenge) +
              '&code_challenge_method=S256&redirect_uri=' +
              this.hostname +
              '&scope=' +
              this.scope +
              '&audience=' +
              this.audience +
              '&state=xyzABC123'

      window.location.replace(url);
    },
    saveDetails() {
      let data = JSON.stringify({
        auth0Url: this.auth0Url,
        clientId: this.clientId,
        scope: this.scope,
        audience: this.audience,
      });

      this.cookies.set("data", data, 60 * 60 * 24 * 30);

      this.$toast.success(`Details Saved Successfully`);
    },
    clearDetails() {
      this.cookies.remove("data");
      (this.auth0Url = ""),
        (this.clientId = ""),
        (this.scope = ""),
        (this.audience = ""),
        this.$toast.success(`Details Removed Successfully`);
    },
  },
  created() {
    console.log("test");
    let verificationKey = this.cookies.get("verificationKey");

    if (verificationKey == null) {
      verificationKey = base64URLEncode(crypto.randomBytes(32));

      this.cookies.set("verificationKey", verificationKey);
    }

    let data = this.cookies.get("data");

    if (data) {
      this.auth0Url = data.auth0Url;
      this.clientId = data.clientId;
      this.scope = data.scope;
      this.audience = data.audience;
    }

    console.log(`Verification key: ${verificationKey}`);

    this.verifier = verificationKey;

    this.challenge = base64URLEncode(sha256(this.verifier));

    if (this.$route.query.code) {
      console.log(this.$route.query.code);

      var options = {
        method: "POST",
        url: this.auth0Url + "/oauth/token",
        headers: { "content-type": "application/json" },
        data: {
          grant_type: "authorization_code",
          client_id: this.clientId,
          code_verifier: this.verifier,
          code: this.$route.query.code,
          redirect_uri: this.hostname,
        },
      };

      var self = this;

      axios
        .request(options)
        .then(function (response) {
          console.log(response.data);
          self.response = response.data;

          self.$toast.success(`Token fetched successfully`);
        })
        .catch(function (error) {
          console.error(error);
          self.response = { error: error.toString() };

          self.$toast.error(`Error while fetching token`);
        });
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
