import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import Toaster from "@meforma/vue-toaster";
import { Vue3Plausible } from "vue-plausible";

createApp(App)
.use(router)
.use(Toaster, {
    position: "top-right",
    pauseOnHover: true
  })
  .use(Vue3Plausible, {
    domain: process.env.VUE_APP_DOMAIN,
    apiHost: process.env.VUE_APP_PLAUSIBLE_API,
    enableAutoPageviews: true,
    enableAutoOutboundTracking: false,
    trackLocalhost: false
  })
.mount('#app')
